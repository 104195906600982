import kittsIsland from "./KittsIsland.jpg";
// import hvarIsland from "./HvarIsland.jpg";
import luciaIsland from "./LuciaIsland.jpeg";
import MaltaIsland from "./MaltaIsland.jpeg";
import CagiliariIsland from "./CagliariIsland.jpeg";
import MadagascarIsland from "./MadagascarIsland.jpeg";
import CyprusIsland from "./CyprusIsland.jpeg";
import BritishVirginIsland from "./BritishVirginIsland.jpeg";
import BoraBoraIsland from "./BoraBoraIsland.jpeg";

export const images = [
  BoraBoraIsland,
  MaltaIsland,
  CagiliariIsland,
  luciaIsland,
  BritishVirginIsland,
];
